.header {
  position: fixed;
  right: 0;
  top: 0;
  padding: 15px;
  left: 136px;
  background-color: #f6f6f6;
  @media only screen and (max-width: 1024px) {
    left: 0;
  }
  .user-details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .user-icon {
      height: 21px;
      margin-right: 16px;
    }
    .user-name {
      font-family: $font-family-heading;
      color: $text-primary;
      font-size: 18px;
      margin-right: 37px;
    }
    .logout {
      cursor: pointer;
      img {
        height: 18px;
      }
    }
  }
}
