.workflow {
  .workflow-card {
    border: 1px solid $primary;
    border-radius: 4px;
    border-left-width: 6px;
    padding: 6px;
    width: 160px;
    height: 130px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #00e1be0d;
    h2 {
      font-size: 50px;
      font-weight: $font-weight-bold;
      margin: 0;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }
    a {
      position: absolute;
      right: 10px;
      bottom: 5px;
    }
  }
}
