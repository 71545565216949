@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'AvenirBlack';
  src: url('../assets/fonts/AvenirLTStd-Black.otf');
}

@font-face {
  font-family: 'AvenirBook';
  src: url('../assets/fonts/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'AvenirRoman';
  src: url('../assets/fonts/AvenirLTStd-Roman.otf');
}

// Bootstrap
@import '../../node_modules/bootstrap/scss/functions';
@import './variables';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/tables';
// @import '../../node_modules/bootstrap/scss/images';
// @import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
// @import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/forms/input-group';
@import '../../node_modules/bootstrap/scss/nav';
// @import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
// @import '../../node_modules/bootstrap/scss/jumbotron';
@import '../../node_modules/bootstrap/scss/alert';
// @import '../../node_modules/bootstrap/scss/progress';
// @import '../../node_modules/bootstrap/scss/media';
// @import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
// @import '../../node_modules/bootstrap/scss/popover';
// @import '../../node_modules/bootstrap/scss/carousel';
// @import '../../node_modules/bootstrap/scss/print';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/spinners';
@import '../../node_modules/bootstrap/scss/helpers';

// Other component styles
@import './sidenav';
@import './pageLayout';
@import './type';
@import './workflow';
@import './header';
@import './loader';
@import './card';
@import './custom';

body {
  margin: 0;
  background-color: $bg-1;
}

.sidenav {
  z-index: 999;
}

.header {
  z-index: 999;
}

// bootstrap style overides
// button ---------------------------
.btn {
  &:active,
  &:focus {
    box-shadow: none;
  }

  &.btn-primary {
    color: $text-primary;

    img {
      margin-right: 20px;
      height: 18px;
      font-size: 18px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: #00bda0;
      border-color: #00bda0;
      color: $secondary;
      box-shadow: none;
    }

    &:active:focus {
      box-shadow: none;
    }
  }

  &.btn-light {
    background-color: transparent;

    &:hover {
      background-color: #f5f5f5;
      border-color: #f5f5f5;
    }
  }

  &.btn-outline-secondary {
    border-color: $secondary;
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 20px;
      height: 18px;
      font-size: 18px;
    }

    &:hover {
      background-color: $secondary;
      color: $text-secondary;

      img {
        filter: invert(110%) brightness(100%) contrast(103%);
        margin-right: 20px;
        height: 18px;
        font-size: 18px;
      }
    }
  }

  &.btn-danger {
    &:hover {
      background-color: #df5252;
      border-color: #df5252;
    }
  }
}

.download-tokens {
  display: inline-block;
  font-weight: 500;
  line-height: 1.2;
  padding: 10px;
  margin-left: 10px;
  background-color: aliceblue;
  color: $primary;
  text-decoration: none;
  padding: 13px 40px;
  font-size: 1.125rem;
  border-radius: 4px;
  border: 1px solid transparent;
}

//breadcrumb------------
.breadcrumb {
  font-family: $font-family-heading;
  text-transform: uppercase;
  color: $text-primary;

  .breadcrumb-item {
    a {
      text-decoration: none;

      &:hover:not(.active) {
        text-decoration: underline;
      }
    }

    &.active {
      color: $text-primary;
    }
  }

  .breadcrumb-item.active {
    @media screen and (max-width: 375px) {
      margin-left: 12px;
      text-indent: -1.1em;
      word-break: break-all;

      &::before {
        display: contents;
      }
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: url(../assets/images/icons/chevron-right.svg);
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 2px;
  }
}

// page header
.page-header {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;
  margin-bottom: 40px;

  @media screen and (max-width: 590px) {
    display: block;

    .btn-primary {
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 590px) {
    display: flex;
  }

  @media screen and (max-width: 1000px) {
    align-items: flex-end !important;

    .breadcrumb-wrapper {
      display: block !important;

      // width: 50%;
      .search-label {
        input {
          margin-left: 0px !important;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1169px) {
    .breadcrumb-wrapper {
      .search-label {
        input {
          min-width: 182px;
        }
      }
    }
  }

  .breadcrumb-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .breadcrumb {
    margin-bottom: 0;
  }

  &.multiple-buttons {
    align-items: flex-end;

    .breadcrumb-wrapper {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      .form-control.table-search {
        margin-left: 0;
        margin-top: 20px;
        min-width: 500px;
        width: 318px;

        @media screen and (min-width: 375px) and (max-width: 600px) {
          min-width: 318px !important;
        }
      }
    }

    .button-wrapper {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      padding-top: 20px;

      .btn {
        margin-left: 20px;
      }
    }

    .button-wrapper2 {
      width: 100%;
      display: flex;
      padding-top: 20px;

      .btn {
        margin-left: 20px;

        &:first-child {
          margin-left: 0px;
        }
      }

      @media screen and (min-width: 601px) {
        .block-1 {
          display: flex;
        }

        .block-2 {
          margin-left: 20px;
          align-self: end;
        }
      }

      @media screen and (max-width: 600px) {
        display: block;

        .block-1 {
          display: flex;

          .btn-outline-secondary {
            &:nth-child(1) {
              width: 60%;
            }

            &:nth-child(2) {
              width: 40%;
            }
          }
        }

        .block-2 {
          margin-top: 20px;

          .btn-primary {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

// bootstrap table styles
.form-control.table-search {
  border-color: #b3b3b3;
  border-width: 2px;
  height: 44px;
  border-radius: 22px;
  min-width: 318px;
  background-color: white;
  margin-left: 114px;
  margin-bottom: 0;
  color: $text-primary;

  &:focus {
    box-shadow: none;
    border-color: $primary;
  }

  &.search-in-card {
    margin-left: auto;
    max-width: 50%;
    border-width: 1px;
  }
}

.react-bootstrap-table {
  background-color: #ffffff;
  padding: 15px 20px;
  border-radius: 12px;
  margin-bottom: 20px;

  &.table-in-card {
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
  }

  .table {
    margin-bottom: 0;

    thead {
      tr {
        border-top: 0;
        border-bottom: 1px solid #d8eae6;

        th {
          border-left: 0;
          border-right: 0;
          border-top: 0;
          font-size: 13px;
          line-height: 24px;
          color: #b3b3b3;
          text-transform: uppercase;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d8eae6;

      &:last-child {
        border-bottom: 0;
      }

      td {
        border-left: 0;
        border-right: 0;
        border-top: 0;
        font-size: 15px;
        line-height: 24px;
        color: $text-primary;
      }
    }
  }
}

// input styles
input {
  &.form-control {
    margin-bottom: 20px;
    color: $text-primary;

    &:focus {
      color: $text-primary;
      border-width: 2px;
    }
  }
}

select {
  &.form-control {
    margin-bottom: 20px;
    color: $text-primary;

    &:focus {
      color: $text-primary;
      border-width: 2px;
    }
  }
}

textarea {
  &.form-control {
    margin-bottom: 20px;
    color: $text-primary;

    &:focus {
      color: $text-primary;
      border-width: 2px;
    }
  }
}

form {
  .error {
    color: red;
    font-size: 0.625rem;
    margin-top: -1rem;
  }

  .invalid-feedback {
    margin-top: -0.75rem;
    margin-bottom: 10px;
  }
}

.form-error {
  font-size: 0.625rem;
  color: #ff6565;
  margin-top: -1rem;
}

.form-buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;

  button {
    margin-left: 30px;
  }
}

.under-construction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 100px;

  img {
    height: 300px;
  }

  h1 {
    font-family: $font-family-heading;
    color: $text-primary;
    margin-top: 20px;
    font-size: 20px;
  }
}

.form-heading {
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 30px;

  h3 {
    font-size: 25px;
    line-height: 34px;
    font-family: $font-family-heading;
    color: $text-primary;
    margin-bottom: 0;
  }

  .form-check {
    margin-bottom: 0;

    @media screen and (max-width: 540px) {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: 540px) {
    display: flex;
  }
}

// tab styles
.tab-content {
  .card {
    border-top-left-radius: 0;
  }
}

//no data styles
.no-data {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main-image {
    height: 100px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 25px;
    color: $text-primary;
    font-family: $font-family-heading;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 16px;
    color: $text-primary;
    margin-bottom: 30px;
  }
}

//form message

//trade account styles
.trade-account-details {

  .info-label {
    margin-bottom: 15px;

    label {
      margin-bottom: 0px;
      font-size: 14px;
    }

    p {
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 600;
    }
  }


  &--btns {
    width: 100%;
    text-align: right;

    .btn {
      margin-left: auto;
    }
  }
}

.transaction-total {
  text-align: right;
  width: 100%;

  p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;

    b {
      margin-left: 20px;
      font-size: 25px;
    }
  }
}

// order form styles
.order-form-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 320px) {
    .order-form {
      .form-buttons {
        .right,
        a {
          width: 100% !important;
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;

    .order-form {
      width: 100% !important;

      .available-carbon-text {
        text-align: left !important;
      }

      .form-check {
        display: inherit !important;
        text-align: left !important;
      }

      .form-buttons {
        align-items: center !important;

        .right,
        a {
          width: 80%;
        }

        button {
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 550px) and (max-width: 800px) {
    justify-content: space-between !important;

    .order-form {
      width: 60% !important;
    }
  }

  @media screen and (min-width: 425px) and (max-width: 550px) {
    justify-content: space-between !important;

    .order-form {
      width: 50% !important;
    }
  }

  .order-form {
    .checkbox-div {
      text-align: right;

      .form-check {
        display: inline-block;
      }
    }

    .token-release-title {
      margin-top: 30px;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .release-section {
      border: 1px solid lightgray;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 30px;
      position: relative;

      .close-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        display: inline-flex;
        width: 35px;
        height: 35px;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 4px;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }

    .available-carbon-text {
      text-align: right;
      margin-bottom: 30px;
      width: 100%;
      font-size: 14px;
    }

    .add-release {
      text-align: center;
      margin-bottom: 40px;
    }

    .carbon-details {
      &-title {
        font-weight: bold;
        margin-bottom: 30px;
      }

      table {
        width: 100%;
        margin-bottom: 40px;

        tr {
          border-bottom: 1px solid lightgray;
          border-top: 1px solid lightgray;

          td {
            border-right: 1px solid lightgray;
            border-left: 1px solid lightgray;
            padding: 5px 20px;
          }
        }
      }
    }

    .form-buttons {
      justify-content: space-between;

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .preview-wrapper {
    display: flex;

    @media screen and (min-width: 425px) and (max-width: 800px) {
      flex-direction: column;
    }

    @media screen and (max-width: 320px) {
      width: 160px;

      .preview {
        .preview-img {
          img {
            margin-top: 40px;
            height: 10px;
            margin-bottom: 4px;
          }
        }

        p {
          max-width: 75px;
          font-size: 5px;
        }
      }
    }

    @media screen and (min-width: 320px) and (max-width: 425px) {
      width: 100%;
    }

    @media screen and (min-width: 320px) and (max-width: 375px) {
      .preview {
        min-width: 50% !important;
        min-height: 260px !important;

        .preview-img {
          img {
            margin-top: 50px;
            height: 15px;
            margin-bottom: 5px;
          }
        }

        p {
          max-width: 83%;
          font-size: 7px;
        }
      }
    }

    @media screen and (min-width: 375px) and (max-width: 425px) {
      .preview {
        min-width: 50% !important;
        min-height: 330px !important;

        .preview-img {
          img {
            margin-top: 65px;
            height: 15px;
            margin-bottom: 5px;
          }
        }

        p {
          max-width: 85%;
          font-size: 8px;
        }
      }
    }

    @media screen and (max-width: 425px) {
      .preview {
        min-height: 200px;
        margin-left: 0px;
      }
    }
  }

  .preview {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-image: url(../assets/images/device-frame.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 230px;
    max-height: 700px;
    min-width: 230px;
    min-height: 360px;

    @media screen and (min-width: 425px) and (max-width: 800px) {
      max-height: 400px;
      min-height: 350px;
      min-width: 200px !important;
      max-width: 200px !important;
    }

    img {
      margin-top: 100px;
      width: 80px;
      height: auto;
      margin-bottom: 30px;
    }

    p {
      color: white;
      max-width: 140px;
      text-align: center;
      font-size: 9px;
    }

    &.preview-2 {
      background-image: url(../assets/images/device-frame-2.png);

      p {
        color: $black;
      }
    }
  }

  &.deliver-token-wrapper {
    .order-form {
      width: 100%;
    }
  }
}

.preview {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-image: url(../assets/images/device-frame.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 230px;
  max-height: 700px;
  min-width: 230px;
  min-height: 700px;

  img {
    margin-top: 100px;
    width: 80px;
    height: auto;
    margin-bottom: 30px;
  }

  p {
    color: white;
    max-width: 150px;
    text-align: center;
    font-size: 10px;
  }

  &.preview-2 {
    background-image: url(../assets/images/device-frame-2.png);

    p {
      color: $black;
    }
  }
}

.view-certificate {
  width: 100%;
}

.uppy-DashboardContent-bar {
  z-index: 1 !important;
}

// order view styles

.order-details {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .carbon-emmission {
    .units {
      display: grid;
      // @media screen and (max-width:359px) {
      //   .strong {
      //     display: contents;
      //     span {
      //       margin-left: 0px !important;
      //     }
      //   }
      // }
    }

    .badge {
      background-color: #ff83739c;
      padding: 6px 22px;
      border: 1px solid #ff8373;
      border-radius: 20px;
      width: fit-content;
      color: black;

      @media screen and (min-width: 359px) {
        margin-left: 15px;
      }
    }
  }

  .order-details-header {
    @media screen and (max-width: 426px) and (min-width: 768px) {
      .order-details-buttons {
        .order-detail-btns {
          .download-tokens {
            width: 50%;
          }
        }
      }
    }

    @media screen and (max-width: 600px) {
      .order-details-buttons {
        .order-detail-btns {
          justify-content: flex-start !important;

          a {
            margin-left: 0px;
          }
        }
      }
    }

    @media screen and (max-width: 426px) {
      .order-details-buttons {
        display: flex;
        flex-direction: column;

        a,
        button {
          width: 100%;
          margin: 0px 0px 10px 0px !important;

          &:last-child {
            margin: 0px;
          }
        }
      }
    }

    .button-section {
      display: flex;

      @media screen and (max-width: 426px) {
        flex-direction: column;

        button {
          width: 100%;
          margin: 0px 0px 10px 0px !important;
        }
      }

      button,
      a {
        margin-right: 20px;
      }

      .badge {
        font-size: 0.9rem;
        padding: 12px 30px;
        border-radius: 20px;
        color: black;
        margin-left: 15px;
      }

      .DRAFT {
        background-color: #d6eaf8;
        border: 1px solid #2e86c1;
      }

      .SUBMITTED {
        background-color: #daf7a6;
        border: 1px solid #229954;
      }

      .AUTHORISED {
        background-color: #f9e79f;
        border: 1px solid #f1c40f;
      }

      .DELETED {
        background-color: #ffa2a28f;
        border: 1px solid #f30000;
      }

      .VOIDED {
        background-color: #e8daef;
        border: 1px solid #7d3c98;
      }

      .PAID {
        background-color: #ffe6a28f;
        border: 1px solid #f39200;
      }
    }

    .ready-to-claim {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      .switch {
        margin-right: 10px;
      }
    }

    .ready-to-claim-margin {
      margin-right: 20px;
    }

    .order-detail-btns {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 5px;
      }

      @media screen and (min-width: 426px) {
        align-items: center;
      }

      @media screen and (max-width: 426px) {
        flex-direction: column;
      }
    }
  }
}

.order-steps {
  margin-top: 50px;
  margin-bottom: 50px;

  .order-steps-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    padding-left: 0;

    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 50px;
      position: relative;
      min-width: 150px;

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: $primary;
        width: 161px;
        right: -105px;
        top: 20px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      .step-text {
        max-width: 150px;
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
      }

      .step-circle {
        border: 1px solid $primary;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .step-tick {
          display: none;
        }

        &.completed {
          background-color: $primary;

          .step-tick {
            display: inline-block;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1360px) {
    .order-steps-list {
      .step {
        margin-right: 0px;
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1105px) {
    .order-steps-list {
      .step {
        min-width: 130px;

        span {
          max-width: 125px;
        }
      }
    }
  }
}

.order-details-items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 40px -40px;

  .item {
    display: flex;
    flex-direction: column;
    margin: 10px 40px;

    label {
      font-size: 20px;
    }
  }
}

// react select styles
.select-error {
  .react-select {
    &__control {
      border-color: #ff6565;
    }
  }
}

.react-select {
  &__placeholder {
    font-size: 0.8125rem !important;
  }

  &__control {
    border-color: #b3b3b3 !important;
    min-height: 44px;
    margin-bottom: 20px;

    &--is-focused {
      border-color: $primary !important;
      box-shadow: none !important;
      border-width: 2px !important;
    }
  }
}

.viewOrderModal {
  .logo-image {
    width: 100px;
    margin: 0 auto 20px auto;
  }

  .preview-wrapper {
    display: flex;

    .preview {
      min-height: 455px;
    }
  }

  .ready-to-claim {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px 30px 0 0;

    .switch {
      margin-right: 20px;
    }
  }

  .modal-dialog {
    max-width: 1100px;

    .modal-content {
      .modal-footer {
        @media screen and (max-width: 334px) {
          button {
            width: 100%;
          }
        }
      }
    }
  }

  .order-preview {
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: rgb(243, 243, 243);
    text-align: center;

    .order-details-items {
      display: flex;
      margin: 0;
      flex-wrap: wrap;

      .item {
        text-align: left;
        width: 50%;
        margin: 0 0 20px 0;

        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 100% !important;
        }
      }
    }
  }
}

.template-preview-modal {
  .modal-header {
    border-bottom: none;
    background-color: #000000;
  }
  .btn-close {
    color: #fff;
    background: url(../assets/images/icons/closemdl.svg) no-repeat center;
    margin-top: 5px;
    margin-right: 5px;
  }
}
// uppy styles
.uppy-Root {
  margin-bottom: 12px;
}

.uppy-Dashboard-browse {
  color: $primary !important;
  text-decoration: none;

  &:hover,
  &:focus {
    border-color: $primary !important;
  }
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  color: $text-primary !important;
}

// Deliver tokens
.img-format-select {
  margin-bottom: 12px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.token-generate {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 20px 20px;
}

.deliver-token-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .ready-to-claim {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .text {
      margin: 0 10px;
    }
  }
}

.token-preview {
  img {
    height: 200px;
  }
}

.token-download {
  font-weight: 400;
  margin: 20px 0;
}

//Analytics page

.analytics-page {
  padding-top: 65px;

  .section1 {
    margin-bottom: 70px;

    .count-col {
      padding-bottom: 10px;

      @media screen and (max-width: 320px) {
        width: 100%;
      }

      .analyticTile {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        background-color: #00e1be0d;
        border-radius: 4px;
        border: 1px solid #00e1be;
        border-left-width: 6px;
        padding: 16px 16px 16px 6px;

        h5 {
          font-size: 3.125rem;
          color: #333333;
          font-weight: 900;
        }

        p {
          font-size: 1rem;
          font-weight: 900;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  .section2 {
    .form-control {
      padding: 8px 0.75rem !important;
    }

    .row-sec {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .chart-section {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 25px;

      div {
        h5 {
          font-size: 1.75rem !important;
        }
      }
    }

    .chart3 {
      margin-top: 50px;
    }

    .recharts-wrapper {
      height: auto !important;
      width: 100% !important;
      max-width: 100% !important;
      display: flex !important;
      flex-direction: column-reverse !important;
      justify-content: space-around;

      svg {
        height: auto !important;
        width: 100% !important;
        max-width: 100% !important;

        .recharts-layer {
          .recharts-pie-labels {
            // .recharts-layer {
            //   &:nth-child(1) , &:nth-child(3){
            //     text {
            //       transform: translate(0px, 10px);
            //     }
            //   }
            //   &:nth-child(2) , &:nth-child(4){
            //     text {
            //       transform: translate(0px, -10px);
            //     }
            //   }
            // }
          }
        }
      }
    }

    .recharts-legend-wrapper {
      left: 0px !important;
      max-width: 100%;
      width: 100%;
      height: auto;

      .recharts-default-legend {
        .recharts-legend-item {
          display: inline-flex !important;

          svg {
            margin-right: 20px !important;
            height: 20px !important;

            // border-radius: 100% !important;
            path {
              // border-radius: 100% !important;
            }
          }

          .recharts-legend-item-text {
            width: 100%;
          }
        }
      }
    }

    .chart1 {
      .recharts-wrapper {
        .recharts-pie {
          transform: translate(180px, 50px);
        }
      }

      .recharts-legend-wrapper {
        left: 0px !important;
        position: relative !important;
        top: 0px !important;
        margin-top: 25px;
      }

      .recharts-default-legend {
        margin-top: 25px;
        text-align: center !important;

        .recharts-legend-item.legend-item-2,
        .recharts-legend-item.legend-item-3 {
          margin-left: -13px;
          margin-right: 0px !important;

          svg {
            margin-right: 0px !important;
          }
        }

        .recharts-legend-item.legend-item-0,
        .recharts-legend-item.legend-item-1 {
          margin-right: 20px !important;
        }
      }
    }

    .chart2 {
      .recharts-wrapper {
        .recharts-legend-wrapper {
          ul {
            .recharts-legend-item.legend-item-0 {
              width: 224px;
              margin-left: -48px;

              svg {
                margin-right: 0px !important;
              }

              span {
                margin-left: -7px;
              }
            }

            .recharts-legend-item.legend-item-1 {
              margin-left: -22px;
            }

            .recharts-legend-item.legend-item-2 {
              margin-left: -33px;
              margin-right: 0px !important;

              span {
                margin-left: -9px;
                width: 129px;
              }
            }
          }
        }
      }
    }

    .chart3 {
      .recharts-wrapper {
        .recharts-legend-wrapper {
          ul {
            .recharts-legend-item {
              span {
                margin-left: 15px;
              }
            }

            .recharts-legend-item.legend-item-2 {
              margin-left: -30px;

              span {
                margin-left: -14px;
                width: 125px;
              }
            }
          }
        }
      }
    }

    .chart2,
    .chart3 {
      .recharts-legend-wrapper {
        position: absolute !important;
      }

      .recharts-default-legend {
        width: 185px;
      }
    }

    @media screen and (max-width: 320px) {
      .chart1,
      .chart2,
      .chart3 {
        .recharts-wrapper {
          svg {
            width: 350px !important;

            .recharts-pie {
              transform: translate(-185px, 50px) !important;
            }
          }
        }
      }
    }

    @media screen and (min-width: 281px) and (max-width: 320px) {
      .chart1,
      .chart2,
      .chart3 {
        .recharts-wrapper {
          svg {
            .recharts-pie {
              transform: translate(-150px, 50px) !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 320px) {
      .chart1 {
        height: 470px;
      }

      .chart2 {
        height: 430px;
      }

      .chart3 {
        height: 380px;
      }

      .chart1,
      .chart2,
      .chart3 {
        .recharts-wrapper {
          overflow: hidden;

          svg {
            max-width: 500px !important;
          }
        }
      }
    }

    @media screen and (min-width: 321px) {
      .chart1 {
        .row {
          margin-bottom: 40px;
        }
      }
    }

    @media screen and (min-width: 375px) {
      .chart2,
      .chart3 {
        .recharts-wrapper {
          .recharts-pie {
            transform: translate(180px, 40px);
          }
        }

        .recharts-legend-wrapper {
          top: 45px !important;
        }
      }
    }

    @media screen and (max-width: 375px) {
      .chart2,
      .chart3 {
        .recharts-wrapper {
          margin-top: -50px;

          .recharts-pie {
            transform: translate(50px, 65px);
          }
        }

        .recharts-legend-wrapper {
          top: 25px !important;
        }
      }

      .row-sec {
        .row {
          margin-bottom: 50px;
        }
      }
    }

    @media screen and (max-width: 426px) {
      .chart1,
      .chart2 {
        .input-fld {
          .input1 {
            div.react-select__control {
              margin-bottom: 10px !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .chart3,
      .chart2 {
        margin-top: 30px;
      }
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      .input-fld {
        display: flex;
        justify-content: flex-end;
      }

      .chart1,
      .chart3 {
        .input-fld {
          .input2 {
            margin-left: 25px;
          }
        }
      }
    }

    @media screen and (min-width: 991px) {
      .sub-section1 {
        padding-right: 22px;
      }

      .sub-section2 {
        padding-left: 22px;
      }

      .chart1,
      .chart2,
      .chart3 {
        .input-fld {
          display: flex;
          justify-content: flex-end;

          .input1 {
            width: 56%;
          }
        }
      }

      //.chart1, .chart2, .chart3
      .chart1,
      .chart2 {
        .input-fld {
          .input2 {
            width: 52%;
            margin-left: 10px;

            input {
              height: 67%;
            }
          }
        }
      }
    }
  }
}

.card-descrip {
  padding-top: 10px;
}

.text-muted {
  padding-top: 10px;
}

.viewOrderModal {
  .form-control {
    margin-bottom: 0px;
  }

  .modal-content {
    width: 95%;
  }
}

.integration-page {
  .breadcrumb .breadcrumb-item.active {
    @media screen and (max-width: 375px) {
      text-indent: 0em;
      word-break: break-word;
    }
  }

  @media screen and (min-width: 425px) {
    .card {
      width: 22rem;
    }
  }

  @media screen and (max-width: 590px) {
    .breadcrumb-wrapper {
      width: 100%;
    }
  }

  .detail-btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .card-title {
    font-weight: 700;
  }
}

//upload CSV page
.csv-upload-page {
  .react-bootstrap-table {
    border: 1px solid #d1d1d1;

    tbody tr td {
      vertical-align: middle;
    }
  }

  .btn-primary {
    float: right;
  }
}

//order creation validation
.error-msg {
  color: #ff0000;
  background-color: rgb(255, 234, 232);
  border-left: 2px solid #ff0000;
  border-radius: 4px;
  padding: 10px 20px;
}

//transaction uploader status styles

.alert-row {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #d34e4e !important;
  outline-offset: -2px;

  td {
    &:nth-child(9) {
      background-color: rgb(255, 234, 232);
    }
  }
}

.success-row {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #28a745 !important;
  outline-offset: -2px;

  td {
    &:nth-child(9) {
      background-color: #98db9c4f;
    }
  }
}

.status-sec {
  display: flex;

  // justify-content: space-between;
  .status-msg {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .information-icon {
    width: 12%;
    margin-right: 5px;

    @media only screen and (min-width: 2000px) {
      width: 8%;
    }
  }

  .status-icon {
    width: 25%;

    // margin-right: 10px;
    @media only screen and (min-width: 1900px) {
      width: 16%;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-self: center;
  }
}

.btn-in-card {
  display: flex;
  justify-content: flex-end;
}

.send-invoice {
  background-color: #f6f6f6;

  .add-release {
    display: flex;
    justify-content: center;
  }
}

.send-invoice-sec {
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: white;
}

.invoice-col.currency {
  display: block !important;
}

.invoice-col {
  border-right: 0px;
  border-left: 1px solid #d8eae6;
  padding: 10px 0px 20px 0px;
  border-top: 0;
  border-bottom: 0;

  .form-label {
    line-height: 36px;
    width: 100%;
    border-bottom: 1px solid #d8eae6;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    // @media screen and (max-width:768px) {
    //   padding-left: 0px !important;
    //   padding-right: 0px !important;
    //   margin-left: 20px;
    //   margin-right: 20px;
    // }
  }

  .input-sec,
  .input-group {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .invalid-feedback {
      width: 20%;
      position: absolute;
    }
  }

  .input-grp-feedback {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }

  &:nth-child(1) {
    border-left: 0;
    padding-left: 20px;

    .input-sec,
    .form-label,
    .input-group {
      padding-left: 0px;
    }
  }

  &:nth-child(4) {
    padding-right: 20px;
    border-right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.remove-button {
  @media screen and (min-width: 500px) {
    padding-right: 20px;
  }

  .remove-btn {
    margin-top: 36px;
    padding-top: 15px;
    border-top: 1px solid #d8eae6;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64%;

    .close-btn {
      display: contents;

      img {
        @media screen and (min-width: 768px) {
          width: 15% !important;
        }

        @media screen and (max-width: 768px) {
          width: 6%;
        }
      }
    }
  }
}

.header {
  .toast-container .toast.bg-success {
    border-left: 5px solid #198754;

    img {
      filter: invert(39%) sepia(65%) saturate(529%) hue-rotate(100deg) brightness(93%) contrast(88%);
    }
  }

  .toast-container .toast.bg-danger {
    border-left: 5px solid #eb0000;

    img {
      filter: invert(9%) sepia(99%) saturate(6630%) hue-rotate(1deg) brightness(97%) contrast(109%);
    }
  }

  .toast-container .toast {
    background-color: white;
    border-radius: 6px;
    padding: 10px 20px;
    margin-right: 5px;
    margin-top: 20px;

    @media screen and (min-width: 1024px) {
      margin-left: 25px;
    }

    @media screen and (max-width: 1024px) {
      margin-left: 0px;
    }

    .toast-header {
      font-weight: 900;
      font-size: 1.1rem;
      margin-bottom: 2px;

      img {
        margin-top: -5px;

        @media screen and (max-width: 424px) {
          width: 8%;
        }

        @media screen and (min-width: 425px) and (max-width: 768px) {
          width: 4%;
        }

        @media screen and (min-width: 768px) {
          width: 2.3%;
        }

        margin-right: 5px;
        margin-left: -5px;
      }

      button {
        float: right;
        border-radius: 0px;
        width: 0.3em;
        height: 0.3em;
      }
    }

    .toast-body {
      color: #9d9d9d;
      font-size: 0.9rem;
    }
  }
}

.recharts-legend-wrapper {
  display: none;
}

.legend-container {
  position: relative;
  width: auto;
  height: auto;
  display: flex;
}

.chart-legend {
  position: absolute;
  width: auto;
  height: auto;

  ul {
    list-style: none;
    padding: 0px;

    li {
      display: flex;
      margin-right: 20px;
      color: #4d4f5c;

      span {
        display: inline-block;
        position: relative;
        height: 12px;
        width: 20px;
        margin-right: 10px;
        border-radius: 100px;
        margin-top: 6px;
      }

      p {
        max-width: 100%;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.chart1,
.chart2,
.chart3 {
  .chart-legend {
    @media screen and (max-width: 570px) {
      p {
        margin-bottom: 0.5rem;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 850px) {
      p {
        margin-bottom: 0.5rem;
      }
    }

    @media screen and (min-width: 850px) and (max-width: 1126px) {
      p {
        margin-bottom: 0.6rem;
      }
    }

    @media screen and (min-width: 1126px) and (max-width: 1260px) {
      p {
        margin-bottom: 0.8rem;
      }
    }
  }
}

.chart1 {
  .chart-legend {
    position: absolute;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 321px) and (max-width: 380px) {
      top: -115px;
    }

    @media screen and (min-width: 381px) and (max-width: 425px) {
      top: -150px;
    }

    @media screen and (min-width: 425px) and (max-width: 500px) {
      top: -166px;
    }

    @media screen and (min-width: 500px) and (max-width: 570px) {
      top: -200px;
    }

    @media screen and (min-width: 570px) and (max-width: 700px) {
      top: -215px;
    }

    @media screen and (min-width: 700px) and (max-width: 768px) {
      top: -230px;
    }

    @media screen and (min-width: 768px) and (max-width: 850px) {
      top: -156px;
    }

    @media screen and (min-width: 850px) and (max-width: 1126px) {
      top: -180px;
    }

    @media screen and (min-width: 1126px) and (max-width: 1260px) {
      top: -200px;
    }

    @media screen and (min-width: 1260px) and (max-width: 1350px) {
      top: -220px;
    }

    @media screen and (min-width: 1350px) {
      top: -240px;
    }

    ul {
      position: relative;

      &:first-child {
        li {
          &:nth-child(1) {
            span {
              background-color: #56d9fe;
            }
          }

          &:nth-child(2) {
            span {
              background-color: #a3a0fb;
            }
          }

          &:nth-child(3) {
            span {
              background-color: #ff8373;
            }
          }

          &:nth-child(4) {
            span {
              background-color: #000000;
            }
          }

          &:nth-child(5) {
            span {
              background-color: #c0c0c0;
            }
          }
        }
      }
    }
  }
}

.chart2,
.chart3 {
  .chart-legend {
    @media screen and (min-width: 321px) and (max-width: 350px) {
      top: -75px;
    }

    @media screen and (min-width: 350px) and (max-width: 380px) {
      top: -85px;
    }

    @media screen and (min-width: 380px) and (max-width: 425px) {
      top: -112px;
    }

    @media screen and (min-width: 425px) and (max-width: 490px) {
      top: -125px;
    }

    @media screen and (min-width: 490px) and (max-width: 570px) {
      top: -135px;
    }

    @media screen and (min-width: 570px) and (max-width: 600px) {
      top: -150px;
    }

    @media screen and (min-width: 600px) and (max-width: 768px) {
      top: -175px;
    }

    @media screen and (min-width: 768px) and (max-width: 850px) {
      top: -115px;
    }

    @media screen and (min-width: 850px) and (max-width: 1126px) {
      top: -127px;
    }

    @media screen and (min-width: 1126px) and (max-width: 1260px) {
      top: -140px;
    }

    @media screen and (min-width: 1260px) {
      top: -170px;
    }
  }
}

.chart2 {
  .chart-legend {
    ul {
      li {
        &:nth-child(1) {
          span {
            background-color: #9fe2bf;
          }
        }

        &:nth-child(2) {
          span {
            background-color: #2ee13a;
          }
        }

        &:nth-child(3) {
          span {
            background-color: #c0c0c0;
          }
        }
      }
    }
  }
}

.chart3 {
  .chart-legend {
    ul {
      li {
        &:nth-child(1) {
          span {
            background-color: #ff7f50;
          }
        }

        &:nth-child(2) {
          span {
            background-color: #ffbf00;
          }
        }

        &:nth-child(3) {
          span {
            background-color: #c0c0c0;
          }
        }
      }
    }
  }
}

.view-invoice {
  padding: 10px;
  margin-left: 10px;
  background-color: aliceblue;
  color: $primary;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 4px;
  border-left: 4px solid $primary;

  &:hover {
    border-left-color: #00bda0;
  }
}

.view-order-link {
  text-decoration: unset;
  padding-right: 10px;
  background-color: #d7f4f59e;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  color: #00bb9e;

  &:hover {
    color: #009981;
    background-color: #bee5e7;
  }
}

.re-order-link {
  margin-left: 20px;
  text-decoration: unset;
  padding-right: 10px;
  background-color: #e5f5d7b8;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  color: #00bb9e;

  &:hover {
    color: #009981;
    background-color: #d1e5c1;
  }
}

@media only screen and (min-width: 425px) and (max-width: 700px) {
  .order-form-wrapper .order-form .form-buttons .right {
    button {
      margin-left: 0px !important;
    }
  }

  .form-buttons {
    display: block !important;
  }
}

@media only screen and (max-width: 700px) {
  .order-form-wrapper .order-form .form-buttons .right {
    display: block !important;

    button {
      margin-bottom: 10px !important;
    }
  }

  .form-buttons {
    a {
      margin-bottom: 10px !important;
    }
  }
}

@media only screen and (max-width: 505px) {
  .form-buttons {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;

    .btn-light {
      margin-bottom: 10px;
    }

    button {
      margin-left: 0px;
    }
  }
}

.order-form-wrapper {
  justify-content: flex-start;

  .preview {
    .preview-img {
      width: 140px !important;
      display: flex;
    }

    img {
      margin-top: 65px;
      margin-bottom: 8px;
      margin-right: auto !important;
      margin-left: auto !important;
      height: 40px;
      width: auto;
    }
  }

  .preview-2 {
    img {
      margin-top: 70px;
      margin-left: 1px;
      margin-right: auto !important;
      margin-left: auto !important;
    }

    p {
      width: 138px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .order-form-wrapper {
    .order-form {
      width: 50%;
    }

    .preview-wrapper {
      .preview {
        min-width: 60%;
        max-width: 60%;

        img {
          margin-top: 75px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .order-form-wrapper .preview-wrapper {
    .preview {
      min-width: 170px;
    }
  }
}

._loading_overlay_overlay {
  margin-top: 55.5px !important;
}

.calendar-container {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
}

.calender-icon {
  margin-left: -4px;
  margin-bottom: 20px;
  border: 1px solid #b3b3b3;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  width: 10.6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 90% !important;

  .react-datepicker__input-container {
    display: block;

    .form-control {
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border-bottom-left-radius: 4px !important;
      border-top-left-radius: 4px !important;
    }
  }
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__navigation {
      margin-top: 8px;
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;

        .react-datepicker__day-names {
          .react-datepicker__day-name {
            font-weight: 600;
          }
        }

        .react-datepicker__header__dropdown {
          .react-datepicker__month-dropdown-container {
            margin-left: -10px;

            .react-datepicker__month-read-view {
              .react-datepicker__month-read-view--down-arrow {
                margin-top: 2px;
              }
            }
          }

          .react-datepicker__year-dropdown-container {
            margin-left: 20px;

            .react-datepicker__year-read-view {
              .react-datepicker__year-read-view--down-arrow {
                margin-top: 2px;
              }
            }
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            line-height: 1.5rem;
          }

          .react-datepicker__day--outside-month {
            color: darkgray;
          }

          .react-datepicker__day--today {
            border: 2px solid black;
            border-radius: 0px;
          }
        }
      }
    }

    .react-datepicker__today-button {
      background: white;
      border-top: none;
      color: #2a87d0;
      font-weight: normal;
    }
  }
}

.react-bootstrap-table-pagination-list {
  @media screen and (max-width: 768px) {
    width: 100%;

    ul {
      float: right;
      width: inherit;
    }
  }
}

@media screen and (max-width: 550px) {
  .customer-account {
    li {
      width: min-content;
    }
  }
}

@media screen and (min-width: 1105px) and (max-width: 1360px) {
  .order-steps .order-steps-list .step:after {
    width: 110px;
    right: -55px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1105px) {
  .order-steps .order-steps-list .step:after {
    width: 90px;
    right: -45px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1024px) {
  .order-steps .order-steps-list .step {
    margin-right: 0px;

    &::after {
      width: 110px;
      right: -55px;
    }
  }
}

@media screen and (max-width: 960px) {
  .order-steps .order-steps-list .step {
    margin-right: 0px;
    min-width: 115px;

    .step-text {
      max-width: 100px;
    }

    &::after {
      width: 76px;
      right: -38px;
    }
  }
}

@media screen and (max-width: 768px) {
  .order-steps {
    overflow-x: scroll;
  }

  .order-details-header {
    button {
      width: min-content;
    }
  }
}

@media screen and (max-width: 600px) {
  .order-details-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .viewOrderModal .preview-wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .viewOrderModal .preview-wrapper {
    justify-content: center;

    .preview {
      min-height: 387px;
      min-width: 165px;
      margin-left: 0px;

      img {
        margin-top: 70px;
        margin-bottom: 10px;
      }

      p {
        max-width: 140px;
        font-size: 8px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .viewOrderModal {
    .modal-content {
      .modal-body {
        .preview-wrapper {
          .preview {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.invalid-logo {
  margin-bottom: 18px;
  font-size: 0.625rem;
  color: #ff6565;
}

.user-tbl-responsive,
.customer-tbl-responsive,
.customer-orderhistory-responsive,
.carbon-tbl-responsive {
  .custom-tbl {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1088px) {
  .customer-orderhistory-responsive,
  .order-tbl-responsive {
    background-color: #fff;
    overflow-x: scroll;

    .custom-tbl {
      th,
      td {
        min-width: 150px;
      }

      th {
        &.custom-col {
          min-width: 100px;
        }

        &.custom-col2 {
          min-width: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .user-tbl-responsive {
    background-color: #fff;
    overflow-x: scroll;

    .custom-tbl {
      th,
      td {
        min-width: 150px;
      }

      th {
        &.custom-col {
          min-width: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .carbon-tbl-responsive,
  .customer-tbl-responsive,
  .report-tbl-responsive {
    background-color: #fff;
    overflow-x: scroll;

    .custom-tbl {
      th,
      td {
        min-width: 150px;
      }

      th {
        &.custom-col {
          min-width: 250px;
        }

        &.custom-col2 {
          min-width: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .csv-tbl-responsive {
    border-radius: 12px;
    border: 1px solid #d1d1d1;
    background-color: #fff;
    overflow-x: scroll;
    margin-bottom: 10px;

    .react-bootstrap-table {
      border: 0px;
    }

    .custom-tbl {
      th,
      td {
        min-width: 150px;
      }
    }
  }
}

@media screen and (max-width: 858px) {
  .trade-tbl-responsive {
    background-color: #fff;
    overflow-x: scroll;

    .custom-tbl {
      th,
      td {
        min-width: 150px;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .analytics-page .section2 .chart-section .recharts-wrapper .recharts-pie {
    transform: translate(195px, 50px);
  }

  .chart-legend {
    // margin-top: 46px;
    span {
      margin-top: 3px !important;
    }

    p {
      font-size: 12px;
      margin-bottom: 0.2rem !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .date-from,
  .date-to {
    width: 100%;
  }

  .form-control.table-search {
    min-width: initial !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 320px) {
  .page-header.multiple-buttons .button-wrapper2 .block-1 {
    flex-direction: column !important;

    .btn-outline-secondary {
      width: 100% !important;
      margin-left: 0px;

      &:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}

ul.nav-tabs {
  overflow-y: hidden;
  // overflow-x: scroll;
  flex-wrap: nowrap;

  li {
    button {
      white-space: nowrap;
    }
  }
}

.dropdownmenu {
  .dropdown {
    .btn-link {
      color: #000000;
      padding: 0px;
      margin-left: 20px;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }
}

.uppy-Dashboard-AddFiles-info {
  display: block !important;
}

.serial-s-e {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  :first-child {
    flex-grow: 2;
    margin-right: 10px;
  }

  :nth-child(2) {
    flex-grow: 2;
  }

  button {
    // flex-grow: 0;
    margin-left: 10px;
    margin-top: 13px;
    /* padding: 13px; */
    font-size: 13px;
  }

  .rel-field {
    position: relative;

    .invalid-feedback {
      position: absolute;
      bottom: -14px;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 525px) {
    display: block;

    button {
      // flex-grow: 0;
      margin-left: 0px;
      margin-top: 5px;
      margin-bottom: 10px;
      /* padding: 13px; */
      font-size: 13px;
    }

    .rel-field {
      margin-bottom: 32px;

      .invalid-feedback {
        bottom: -30px;
      }
    }
  }
}

.wrap-pagination {
  display: flex;

  .btn-pagi {
    display: block;
    padding: 0.375rem 0.75rem !important;
    background-color: #fff;
    border: 1px solid #d6e3f1;
    height: fit-content;
    color: #00e1be;

    .active {
      color: #fff;
      background-color: #00e1be;
      border-color: #00e1be;
    }
  }
  .btn-pagi-disabled {
    color: #6c757d !important;
    pointer-events: none;
  }

  .pagination {
    align-items: center;
    align-content: center;

    li {
      a {
        display: block;
        padding: 0.375rem 0.75rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        color: #00e1be;
      }
    }

    .disabled {
      a {
        color: #6c757d !important;
        pointer-events: none;
      }
    }

    .previous,
    .next {
      a {
        position: relative;
        display: block;
        color: #00e1be;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #dee2e6;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
      }
    }

    .active {
      a {
        z-index: 3;
        color: #fff;
        background-color: #00e1be;
        border-color: #00e1be;
      }
    }
  }
}

.page-header {
  .breadcrumb-wrapper {
    .breadcrumb-item {
      a {
        color: #333333;
      }
    }
  }
}

.custom-tbl {
  position: relative;

  .table-body-backdrop {
    background: rgb(0, 0, 0, 0.7);
    display: block;
    height: -webkit-fill-available;
    width: 100%;
    position: absolute;
  }
}
