.sidenav {
  background-color: $secondary;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .logo {
    display: none;
    margin-bottom: 82px;
    margin-left: auto;
    margin-right: auto;
  }
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    li {
      a {
        padding: 20px;
        color: $text-secondary;
        display: flex;
        text-decoration: none;
        text-transform: uppercase;
        font-family: $font-family-heading;
        span {
          display: none;
        }
        &.active {
          color: $primary;
          img {
            filter: invert(40%) sepia(70%) saturate(1438%) hue-rotate(121deg) brightness(98%) contrast(103%);
          }
        }
        &:hover {
          color: $primary;
          img {
            filter: invert(40%) sepia(70%) saturate(1438%) hue-rotate(121deg) brightness(98%) contrast(103%);
          }
        }
        @media only screen and (max-width: 450px) {
          padding: 9px;
          img {
            width: 22px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1025px) {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 136px;
    padding-top: 23px;
    min-height: 600px;
    overflow-y: auto;
    .logo {
      display: block;
      height: 29px;
    }
    ul {
      flex-direction: column;
      li {
        a {
          flex-direction: column;
          font-size: 12px;
          margin-bottom: 55px;
          padding: 0;
          img {
            height: 26px;
            margin-bottom: 10px;
          }
          span {
            display: block;
          }
        }
      }
    }
  }
}

img.reports-icon {
  width: 30px;
  margin: auto;
}

@media only screen and (min-width: 1023px) {
  img.reports-icon {
    margin-bottom: 10px;
  }
}

// .sidenav {
//   background-color: $secondary;
//   min-width: 136px;
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
//   ul {
//     padding: 0;
//     margin: 0;
//     li {
//       a {
//         color: $text-secondary;
//         text-decoration: none;
//         margin-bottom: 64px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         &:hover {
//           color: $primary;
//         }
//       }
//     }
//   }
// }