.custom-row {
  justify-content: space-between;
  padding-right: 0;
  margin-bottom: 0.5rem;

  .custom-column {
    padding-right: 0;
  }

  .custom-column-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0;
    position: relative;
    bottom: -3px;

    .form-custom-btn {
      background-color: #ffffff;
      border: 1px solid #b3b3b3;
      color: #b3b3b3;

      @media (max-width: 991.98px) {
        width: 100%;
      }
    }
  }

  .custom-input {
    min-width: 150px;

    @media (max-width: 991.98px) {
      width: 100%;
      padding-right: 0;
    }
  }
}

.form-check {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.orders-sec {
  .page-header {
    @media (max-width: 1366.98px) {
      display: block;
    }
  }


  .orders-sec-btngrp {
    display: flex;
    gap: 10px;

    @media (max-width: 1366.98px) {
      margin-top: 20px;
    }
  }
}

.col-table-margins {
  overflow-x: scroll;

}

.table.table-margins {
  margin-top: 20px;
  border: 1px solid #d8eae6;
  border-collapse: separate;
  border-radius: 5px !important;
  border-spacing: 0;
  min-width: 600px;

  thead {
    tr {
      border: none;

    }
  }

  tr {
    border: 1px solid #d8eae6;
  }

  th,
  td {
    border-right: 1px solid #d8eae6;
    padding: 15px 30px;
    width: 30%;
  }

  .form-control {
    margin-bottom: 0 !important;
  }

  :not(:last-child)> :last-child>* {
    border-bottom-color: #d8eae6 !important;
  }

  .wrap-btn-add-order {
    margin: auto;
    text-align: center;


    .btn-add-Order {
      border: none;
      display: block;
      text-align: center;
      margin: auto;
      background-color: #212121;
      color: #ffffff;
    }
  }

  .wrap-btn-min-order {
    vertical-align: middle;
    width: 102px;
    padding: 0;

    .btn-min-order {
      border: none;
      margin: auto;
    }

    .btn-outline-secondary:hover {
      background-color: transparent !important;
      color: #000000;

      img {
        filter: invert(0);
      }
    }

  }


  .invalid-feedback {
    margin-top: 5px;
    margin-bottom: 0;
    color: red;
    font-size: 0.625rem;
  }
}

.Payment-Matrix {
  margin-top: 32px;
}

.es-cert {
  margin-top: -13px;
  display: block;

  @media (max-width: 991.98px) {
    margin-bottom: 20px;
  }
}

.sortable-column {
  cursor: pointer;
  .ic-sort-svg {
    width: 15px;
    margin-left: 5px;
    margin-top: -3px;
  }
}

