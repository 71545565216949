// colors
$primary: #00e1be;
$secondary: #000000;
$text-primary: #333333;
$text-secondary: #ffffff;
$text-tertiary: #b3b3b3;
$danger: #ff6565;
$bg-1: #f6f6f6;
$bg-2: #00e1be0d;

// fonts
$font-family-base: 'AvenirRoman', sans-serif, Arial, -apple-system;
$font-family-heading: 'AvenirBlack', sans-serif, -apple-system;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.6;
$headings-font-weight: 400;

// form style variables---------------------------
$input-padding-y: 11px;
$input-font-family: $font-family-base;
$input-font-size: 13px;
$input-font-weight: $font-weight-normal;
$input-color: $text-primary;
$input-bg: transparent;
$input-border-color: #b3b3b3;
$input-border-radius: 4px;
$input-box-shadow: none;
$input-focus-color: $primary;
$input-focus-box-shadow: none;
$input-placeholder-color: #cecfd0;
$form-label-font-size: 13px;
$form-label-color: #808495;
$form-text-font-size: 10px;
// select
$form-select-bg: transparent;
$form-select-border-radius: 4px;
$form-select-box-shadow: none;
$form-select-font-size: $input-font-size;
$form-select-box-shadow: none;
$form-select-focus-border-color: $primary;
$form-select-focus-box-shadow: none;
$form-select-focus-border-color: $primary;

// button variables -----------------------
$input-btn-padding-y: 13px;
$btn-padding-x: 40px;
$btn-font-size: 18px;
$btn-border-radius: 4px;
$btn-font-weight: $font-weight-medium;
$btn-line-height: 1.2;

// card variables ---------------------------
$card-bg: #ffffff;
$card-border-color: #ffffff;
$card-border-radius: 12px;
$card-spacer-y: 22px;
$card-spacer-x: 25px;

//breadcrumb --------------------------------
$breadcrumb-font-size: 25px;

//Navs--------------------------------------
$nav-link-color: $text-primary;
$nav-link-hover-color: $primary;
$nav-tabs-border-color: transparent;
$nav-tabs-link-active-border-color: $text-secondary;
$nav-tabs-border-radius: 12px;
