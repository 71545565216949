.page-layout {
  display: flex;
  min-height: 100vh;
  position: relative;
  &--wrapper {
    padding: 20px 20px 20px 180px;
    width: 100%;
    background-color: #f6f6f6;
    @media only screen and (max-width: 1024px) {
      padding-left: 20px;
      padding-bottom: 100px;
    }
  }
}
